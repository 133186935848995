/* eslint-disable @typescript-eslint/naming-convention */
import { TreeType } from "@type/form/field.types";

export enum TypeMailsEnum {
  WORKSITE_ELIGIBLE = "WORKSITE_ELIGIBLE",
  WORKSITE_PENDING = "WORKSITE_PENDING",
  WORKSITE_INELIGIBLE = "WORKSITE_INELIGIBLE",
  WORKSITE_GRDF = "WORKSITE_GRDF",
  WORKSITE_COLLECTIF = "WORKSITE_COLLECTIF",
  RGE_PAC_REFUSED = "RGE_PAC_REFUSED",
  RGE_PAC_ACCEPTED = "RGE_PAC_ACCEPTED",
  PAC_ELEARNING_REFUSED = "PAC_ELEARNING_REFUSED",
  PAC_REFUSED = "PAC_REFUSED",
  PAC_RENEWAL_REMINDER = "PAC_RENEWAL_REMINDER",
  PAC_ACCEPTED = "PAC_ACCEPTED",
  RGE_SMOKE_ACCEPTED = "RGE_SMOKE_ACCEPTED",
  INITIALIZE_PASSWORD = "INITIALIZE_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  CHOICE_OF_AUDIT = "CHOICE_OF_AUDIT",
  CANCEL_AUDIT = "CANCEL_AUDIT",
  NO_CHOICE_OF_AUDIT = "NO_CHOICE_OF_AUDIT",
  CHOICE_OF_AUDIT_DONE = "CHOICE_OF_AUDIT_DONE",
  REQUEST_NEW = "REQUEST_NEW",
  REQUEST_FIX = "REQUEST_FIX",
  REQUEST_ARCHIVE = "REQUEST_ARCHIVE",
  MAIL_NOTIFICATION = "MAIL_NOTIFICATION",
  LEAD_REMINDER = "LEAD_REMINDER",
  LEAD_SECOND_REMINDER = "LEAD_SECOND_REMINDER",
}

export const TYPE_MAILS = [
  {
    label: "Gestion d'un compte PG",
    value: "Gestion d'un compte PG",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.INITIALIZE_PASSWORD,
        label: "Mail de l'initialisation d'un compte PG",
      },
      {
        value: TypeMailsEnum.RESET_PASSWORD,
        label: "Mail de l'ré-initialisation d'un mot de passe",
      },
    ],
  },
  {
    label: "Mails Mention Pac Hybride",
    value: "Mails Mention Pac Hybride",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.RGE_PAC_REFUSED,
        label: "Mail des documents RGE de la mention PAC Hybride refusés",
      },
      {
        value: TypeMailsEnum.RGE_PAC_ACCEPTED,
        label: "Mail des documents RGE de la mention PAC Hybride acceptés",
      },
      {
        value: TypeMailsEnum.PAC_REFUSED,
        label:
          "Mail des documents du renouvellement de la mention PAC Hybride refusés",
      },
      {
        value: TypeMailsEnum.PAC_ELEARNING_REFUSED,
        label:
          "Mail des documents de connaissance pour le renouvellement de la mention PAC Hybride refusés",
      },
      {
        value: TypeMailsEnum.PAC_ACCEPTED,
        label:
          "Mail des documents de renouvellement de la mention PAC Hybride acceptés",
      },
      {
        value: TypeMailsEnum.PAC_RENEWAL_REMINDER,
        label: "Mail de rappel de renouvellement de la mention PAC",
      },
    ],
  },
  {
    label: "Mails Mention Conduit de fumée",
    value: "Mails Mention Conduit de fumée",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.RGE_SMOKE_ACCEPTED,
        label: "Mail de la mention Conduit de fumée accepté",
      },
    ],
  },
  {
    label: "Mails Chantier",
    value: "Mails Chantier",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.WORKSITE_ELIGIBLE,
        label: "Mail de chantier éligible",
      },
      {
        value: TypeMailsEnum.WORKSITE_PENDING,
        label: "Mail de chantier en attente",
      },
      {
        value: TypeMailsEnum.WORKSITE_INELIGIBLE,
        label: "Mail de chantier inéligible",
      },
      {
        value: TypeMailsEnum.WORKSITE_GRDF,
        label: "Mail d'envoi de la prime GRDF",
      },
      {
        value: TypeMailsEnum.WORKSITE_COLLECTIF,
        label: "Mail d'envoi d'un chantier collectif à GRDF",
      },
    ],
  },
  {
    label: "Mails Audits CC2",
    value: "Mails Audits CC2",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.CHOICE_OF_AUDIT,
        label: "Mail de choix d'un audit CC2",
      },
      {
        value: TypeMailsEnum.NO_CHOICE_OF_AUDIT,
        label: "Mail de choix d'un audit CC2 imposé",
      },
      {
        value: TypeMailsEnum.CHOICE_OF_AUDIT_DONE,
        label: "Mail de choix du CC2 fait par le PG",
      },
      {
        value: TypeMailsEnum.CANCEL_AUDIT,
        label: "Mail d'annulation de validation du CC2",
      },
    ],
  },
  {
    label: "Mails des demandes",
    value: "Mails des demandes",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.REQUEST_NEW,
        label: "Mail de nouvelle demande",
      },
      {
        value: TypeMailsEnum.REQUEST_FIX,
        label: "Mail de correction de demande",
      },
      {
        value: TypeMailsEnum.REQUEST_ARCHIVE,
        label: "Mail d'archivage de demande",
      },
    ],
  },
  {
    label: "Mail de notification de courrier Yoocan",
    value: TypeMailsEnum.MAIL_NOTIFICATION,
    selectable: true,
  },
  {
    label: "Mail de demande de contact",
    value: "Mail de demande de contact",
    selectable: false,
    children: [
      {
        value: TypeMailsEnum.LEAD_REMINDER,
        label: "Mail de demande de contact pour le PG",
      },
      {
        value: TypeMailsEnum.LEAD_SECOND_REMINDER,
        label: "Mail de rappel de lead",
      },
    ],
  },
] as TreeType[];
